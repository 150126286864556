import { render, staticRenderFns } from "./freeze.vue?vue&type=template&id=2afb3d3f&scoped=true"
import script from "./freeze.vue?vue&type=script&lang=js"
export * from "./freeze.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2afb3d3f",
  null
  
)

export default component.exports