import { render, staticRenderFns } from "./upgrade.vue?vue&type=template&id=3072ade8&scoped=true"
import script from "./upgrade.vue?vue&type=script&lang=js"
export * from "./upgrade.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3072ade8",
  null
  
)

export default component.exports